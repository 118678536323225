<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-list dense style="height: 70vh; overflow-y: auto">
              <div v-for="(order, index) in orders" :key="index">
                <div v-if="order.type == 'week'">
                  <v-subheader  style="font-size: 16px">
                    {{ order.sailingWeek }}
                  </v-subheader>
                  <v-divider></v-divider>
                </div>
                <div v-else-if="order.type == 'vessel'">
                  <v-list-item style="height: 25px">
                    <v-list-item-action>
                      <v-icon small color="grey">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="order.vesselVoyage"
                        style="font-size: 14px; color: grey"
                      >
                        {{ order.vesselVoyage }}
                      </v-list-item-title>
                      <v-list-item-title v-else style="font-size: 14px; color: grey">
                        No Vessel Specified
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-else-if="order.type == 'customer'">
                  <v-list-item style="height: 25px">
                    <v-list-item-action class="ml-4">
                      <v-avatar
                        size="32"
                        :color="order.customer.logo ? 'white' : 'secondary'"
                      >
                        <v-img
                          v-if="order.customer && order.customer.logo"
                          :src="order.customer.logo"
                          contain
                        ></v-img>
                        <h3 v-else>{{ order.customer.name.charAt(0) }}</h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">
                        {{ order.customerName }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                      <v-container>
                        <v-row justify="center">
                            <v-col cols="12" sm="6" md="3">
                            <v-list-item-title style="font-size: 14px">
                              {{ order.orders.length }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Orders
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-list-item-title style="font-size: 14px">
                              {{ order.containers }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Containers
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-list-item-title style="font-size: 14px">
                              {{ order.allocatedPallets }} /
                              {{ order.totalPallets }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                             Allocated
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-list-item-title style="font-size: 14px">
                              {{ order.loadedPallets }} /
                              {{ order.totalPallets }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                             Loaded
                            </v-list-item-subtitle>
                          </v-col>
                          <!-- <v-col cols="12" sm="6" md="4">
                            <v-list-item-title style="font-size: 14px">
                              {{ order.allocatedProgress }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Loaded
                            </v-list-item-subtitle>
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-else>
                  <v-list-item style="height: 25px">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 14px">
                        {{ order.ctoNo }}
                      </v-list-item-title>
                      <v-list-item-subtitle style="font-size: 12px">
                        {{ order.allocatedPallets }}/ {{ order.totalPallets }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action style="font-size: 12px">
                      {{ order.allocatedProgress }}%
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </div>
            </v-list>
            <!-- <v-virtual-scroll
                  :items="orders"
                  :height="'600px'"
                  :width="'100%'"
                  item-height="45">
                  <template v-slot:default="{ item }">
                    <v-list-item v-if="item.type == 'week'" style="border-top: 2px solid grey; font-size: 14px;">
                        <v-subheader>
                            {{ item.sailingWeek }}
                        </v-subheader>
                    </v-list-item>
                    <v-list-item v-else-if="item.type == 'vessel'" style=" font-size: 14px;">
                        <v-list-item-action>
                            <v-icon small color="grey">directions_boat</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title v-if="item.vesselVoyage" style="font-size: 14px">
                                {{ item.vesselVoyage }}
                            </v-list-item-title>
                            <v-list-item-title v-else style="font-size: 14px">
                                No Vessel Specified
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                        <v-list-item v-else style=" font-size: 14px;">
                            <v-list-item-action>
                        </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title style=" font-size: 14px;">
                                    {{ item.ctoNo }}
                                </v-list-item-title>
                                <v-list-item-subtitle style=" font-size: 12px;">
                                    {{ item.allocatedPallets }}/ {{ item.totalPallets }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action style=" font-size: 12px">
                              {{item.allocatedProgress}}%
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                       </v-virtual-scroll> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loadingOrders: false,
    orders: [],
  }),
  created() {
    this.getOrderSummary();
  },
  methods: {
    async getOrderSummary() {
      this.loadingOrders = true;
      this.orders = await this.$API.getOrderDashboardSummary();
      console.log(this.orders);
      this.loadingOrders = false;
    },
  },
};
</script>